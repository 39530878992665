'use strict';
app.factory('customLocalStorageService',
function ($rootScope, localStorageService, DASHBOARDCONSTANT, authService) {

    //Actually this is based on the already-included localStorageService.
    //Why create this extra service?
    //With this, the developer of widgets need not know about the 'keys'

    //Note: Quarto behaves such a way that, it is not shared among browser tabs
    //But Default local storage behavior would share.
    //
    
    function setDashboardFilter(objFilter) {
        const filterKeyBase = DASHBOARDCONSTANT.COOKIES.DASHBOARD_FILTER;
        const filterKey = `${filterKeyBase}-${authService.authentication.clientID}`;
        localStorageService.set(filterKey, objFilter);
    }

    function setQCDashboardFilter(objFilter) {
        const filterKeyBase = DASHBOARDCONSTANT.COOKIES.DASHBOARD_FILTER_QC;
        const filterKey = `${filterKeyBase}-${authService.authentication.clientID}`;
        localStorageService.set(filterKey, objFilter);
    }
    
    function setQFDashboardFilter(objFilter) {
        const filterKeyBase = DASHBOARDCONSTANT.COOKIES.DASHBOARD_FILTER_QF;
        const filterKey = `${filterKeyBase}-${authService.authentication.clientID}`;
        localStorageService.set(filterKey, objFilter);
    }

    //FY: single value, eg. "2018"
    //Period: single value, eg. "12"
    //CalendarYear: single value, eg. "2018"
    //Month: single value, eg."12"
    //ShowLatest: boolean, eg. "True"
    //TimeModeSelection: DASHBOARDCONSTANT, eg. DASHBOARDCONSTANT.MODE.BYFYPD / "BYFYPD"
    //compKey: single value, 5
    //dateFrom: date, eg. "2018-05-01"
    //dateTo: date, eg. "2018-05-31"
    //ouKeys: array, eg. ["11", "16", "17"]
    //ShowBy: string eg. "MTD" <- added by Kee Yong
    function getDashboardFilter() {
        const filterKeyBase = DASHBOARDCONSTANT.COOKIES.DASHBOARD_FILTER;
        const filterKey = `${filterKeyBase}-${authService.authentication.clientID}`;

        const today = new Date();
        const { defaultCompKey, defaultOUKey } = authService.authentication;
        const defaultFilter = {
            compKey: defaultCompKey,
            ouKeys: [defaultOUKey],
            TimeModeSelection: DASHBOARDCONSTANT.MODE.BYFYPD,
            FY: today.getFullYear(),
            Period: today.getMonth() + 1,
            CalendarYear: today.getFullYear(),
            Month: today.getMonth() + 1,
            dateFrom: new Date(today.getFullYear(), today.getMonth(), 1),
            dateTo: new Date(today.getFullYear(), today.getMonth() + 1, 0),
            ShowLatest: true,
            ShowBy: "MTD",
        }
        const result = localStorageService.get(filterKey) ?? defaultFilter;
        if (_.isNil(result.CompKey))
            result.CompKey = defaultCompKey;
        if (_.isNil(result.compKey))
            result.compKey = defaultCompKey;
        if (_.isNil(result.ouKeys))
            result.ouKeys = [defaultOUKey];
        if (_.isNil(result.OUKeys))
            result.OUKeys = [defaultOUKey];
        result.OUKeyList = result.ouKeys.join(","); //help add this info
        return result;
    }

    //QC filter, would just send all params to server. Hence, cannot simply add
    function getQCDashboardFilter() {
        const filterKeyBase = DASHBOARDCONSTANT.COOKIES.DASHBOARD_FILTER_QC;
        const filterKey = `${filterKeyBase}-${authService.authentication.clientID}`;
        const { defaultCompKey, defaultOUKey } = authService.authentication;
        const today = new Date();
        const y = today.getFullYear(), m = today.getMonth();
        const defaultFilter = {
            compKey: defaultCompKey, 
            ouKeys: [defaultOUKey], 
            dateFrom: new Date(y, m, 1),
            dateTo: today,
            TimeModeSelection: 'BYLATEST',
            IsAllOUSelected: false
        };
        const result = localStorageService.get(filterKey) ?? defaultFilter;
        return result;
    }

    function getQCDashboardFilterTimeModeSelection() {
        const dashboardFilter = getQCDashboardFilter();
        return _.get(dashboardFilter, "TimeModeSelection", DASHBOARDCONSTANT.MODE.BYLATEST.value);
    }

    //In case future need to load All OU
    async function getQCDashboardFilterAsync() {
        const filterKeyBase = DASHBOARDCONSTANT.COOKIES.DASHBOARD_FILTER_QC;
        const filterKey = `${filterKeyBase}-${authService.authentication.clientID}`;
        const filterFromStorage = localStorageService.get(filterKey);
        if (!_.isNil(filterFromStorage)) 
            return filterFromStorage;

        const { defaultCompKey, defaultOUKey } = authService.authentication;
        const filterAnd = {
            logic: "and",
            filters: [
                {
                    field: "CompKey",
                    operator: "eq",
                    value: defaultCompKey
                }
            ]
        };
        const dsOU = $rootScope.GetDataSource("ou");
        await dsOU.read();
        dsOU.filter(filterAnd);
        const defaultDashboardOUKeys = dsOU._view.length ? dsOU._view.map(a => a.OUKey) : [defaultOUKey];
        const today = new Date();
        const y = today.getFullYear(), m = today.getMonth();
        const defaultFilter = {
            CompKey: defaultCompKey, //ideally use capital
            OUKeys: defaultDashboardOUKeys, //ideally use capital
            dateFrom: new Date(y, m, 1),
            dateTo: today,
            TimeModeSelection: 'BYLATEST',
            IsAllOUSelected: false
        };
        return defaultFilter;
    }

    return {
        setDashboardFilter,
        setQCDashboardFilter,
        setQFDashboardFilter,
        getDashboardFilter,
        getQCDashboardFilter,
        getQCDashboardFilterTimeModeSelection 
    }
});